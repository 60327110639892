import { useUserAuthQuery, useUserQuery, type UserQuery } from 'codegen/generated/user'
import { isLoggedInQuerySelector, getUserIdSelector } from './selectors'
import { useQueryClient } from '@tanstack/react-query'

export function useIsLoggedInQuery() {
  return useUserAuthQuery(undefined, {
    select: isLoggedInQuerySelector,
  })
}

export function useSetUserQuery() {
  const queryClient = useQueryClient()

  function setUserQuery(user: UserQuery) {
    queryClient.setQueryData<UserQuery>(useUserQuery.getKey(), user)
  }

  return { setUserQuery }
}

export function useUserIdQuery() {
  return useUserAuthQuery(undefined, {
    select: getUserIdSelector,
  })
}
