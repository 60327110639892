'use client'

import * as React from 'react'

type OpenModalCallback = (open: boolean) => void

type OpenModalOptions = {
  successCallback?: VoidFunction
  modalOpenChangeCallback?: OpenModalCallback
}

type UserAuthContextModel = {
  isOpen: boolean
  setIsOpen(open: boolean): void
  openModal(step?: Step, options?: OpenModalOptions): void
  closeModal(): void
  step: Step
  setStep(step: Step): void
  formState: FormData | null
  setFormState(formData: FormData): void
  errorMessage: React.ReactNode | null
  setErrorMessage(message: React.ReactNode | null): void
  successMessage: React.ReactNode | null
  setSuccessMessage(message: React.ReactNode | null): void
  //Function to be executed after successful login or registration
  successCallback: VoidFunction | null
  modalOpenChangeCallback: OpenModalCallback | null
}

export const UserAuthModalContext = React.createContext<UserAuthContextModel | null>(null)

export type Step = 'login' | 'login-otp' | 'register' | 'register-otp' | 'forget-password'

export const UserAuthModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [errorMessage, setErrorMessage] = React.useState<React.ReactNode | null>(null)
  const [successMessage, setSuccessMessage] = React.useState<React.ReactNode | null>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [step, setStep] = React.useState<Step>('register')
  const [formState, setFormState] = React.useState<FormData | null>(null)
  const [onSuccessCallback, setOnSuccessCallback] = React.useState<VoidFunction | null>(null)
  const [onModalOpenChangeCallback, setOnModalOpenChangeCallback] =
    React.useState<OpenModalCallback | null>(null)

  const openModal = (step: Step = 'register', options?: OpenModalOptions) => {
    const { successCallback, modalOpenChangeCallback } = options || {}

    setStep(step)

    if (successCallback) {
      setOnSuccessCallback(successCallback)
    }
    if (modalOpenChangeCallback) {
      setOnModalOpenChangeCallback(modalOpenChangeCallback)
    }
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleStepChange = (step: Step) => {
    setStep(step)
    setErrorMessage(null)
    setSuccessMessage(null)
  }

  return (
    <UserAuthModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        openModal,
        closeModal,
        step,
        setStep: handleStepChange,
        formState,
        setFormState,
        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage,
        successCallback: onSuccessCallback,
        modalOpenChangeCallback: onModalOpenChangeCallback,
      }}
    >
      {children}
    </UserAuthModalContext.Provider>
  )
}

export function useUserAuthModal() {
  const context = React.useContext(UserAuthModalContext)

  if (!context) {
    throw new Error('useUserAuthModal must be used within a UserAuthModalContext')
  }

  return context
}
